import GivingProxy from '@/proxy/GivingProxy'
import SessionProxy from '@/proxy/SessionProxy'
import stores from '@/stores'
import UserProxy from '@/proxy/UserProxy'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'
import objectHelpers from '@/helpers/objectHelpers.js'

export default {
  /**
   * clearProfileData
   */
  async clearProfileData() {
    this.profileData.username = ''
    this.profileData.firstname = ''
    this.profileData.lastname = ''
    this.profileData.address = ''
    this.profileData.city = ''
    this.profileData.state = ''
    this.profileData.zip = ''
    this.profileData.cellphone = ''
    this.profileData.email = ''
  },

  /**
   * checkProfile
   * checks for a profile based on email.
   *  @param payload
   * @returns { Promise < void>}
   */
  async checkProfile(payload) {
    const proxy = new GivingProxy()
    try {
      const response = await proxy.checkProfile(payload)
      if (response?.data) {
        return response.data
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return {}
  },

  /**
   * updateProfileIfDirty
   * updates the profile if it has changed
   * @returns {Promise<void>}
   */
  async updateProfileIfDirty() {
    if (!this.profileData.portalemail && this.profileData.email1) {
      this.profileData.portalemail = this.profileData.email1
    }

    const equal = objectHelpers.compareObjects(this.profileData, this.originalProfileData)

    if (!equal) {
      const updateMobileGiverPayload = {
        ...this.profileData,
        webId: stores.useOrganizationStore().getWebId
      }
      await this.updateMemberProfile(updateMobileGiverPayload, true)
    }
  },

  /**
   * createMemberProfile
   * creates a new giver profile and all related data in a single API call.
   * This is the profile endpoint on the Giving API.
   *  @param payload
   * @returns {Promise<void>}
   */
  async createMemberProfile(payload) {
    const response = {}
    response.success = false
    const proxy = new UserProxy()
    try {
      const response = await proxy.createMemberProfile(payload)
      if (response?.data) {
        response.success = true
        return response
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  },

  async createMobileGivingProfile(payload) {
    const response = {}
    response.success = false
    const proxy = new UserProxy()
    try {
      const response = await proxy.createMobileGivingMemberProfile(payload)
      if (response?.data) {
        response.success = true
        return response
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      return error
    }
    return response
  },

  /**
   * @param $t i18n instance
   * @param {object} responseResult
   * @param {string} message
   * Build failure message for toast
   */
  buildCreateMobileGivingProfileFailureMessage($t, responseResult) {
    switch (responseResult?.data?.message) {
      case 'MULTIPLE_MATCH':
        return $t('giving.textToGive.errors.multipleMatchingAccounts', {
          orgName: stores.useOrganizationStore().getOrgName
        })
      case 'PHONE_DOES_NOT_MATCH_EXISTING_ACCOUNT':
        if (responseResult.data.accountType === 1) {
          //existing Online Giving user
          return $t('giving.textToGive.errors.phoneDoesNotMatchExistingOGAccount')
        } else {
          // not an online giving user
          return $t('giving.textToGive.errors.phoneDoesNotMatchExistingLegacyAccount', {
            orgName: stores.useOrganizationStore().getOrgName
          })
        }
      case 'EMAIL_DOES_NOT_MATCH_EXISTING_ACCOUNT':
        return $t('giving.textToGive.errors.emailDoesNotMatchExistingAccount', {
          orgName: stores.useOrganizationStore().getOrgName
        })
      default:
        if (responseResult.processorResponse) {
          return `Failure reason: ${responseResult.status} - ${responseResult.message} -
        ${responseResult.processorResponse}`
        } else if (responseResult.additionalAchErrorMessage) {
          return `Failure reason: ${responseResult.status} - ${responseResult.message} -
        ${responseResult.additionalAchErrorMessage}`
        } else if (responseResult.data?.error) {
          return `Failure reason: ${responseResult.status} ${responseResult.statusText} - ${responseResult.data.error}`
        }
    }
  },

  async updateMemberProfile(payload, isMobileGiving = false) {
    let response = {}
    response.success = false
    const proxy = new UserProxy()
    try {
      response = isMobileGiving
        ? await proxy.updateMobileGivingMemberProfile(payload)
        : await proxy.updateMemberProfile(payload)
      if (response?.data) {
        this.profileData = response.data
        if (!this.originalProfileData?.portalemail) {
          this.originalProfileData.portalemail = response.data.portalemail
        }
        return { ...response.data, success: true }
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      return { success: false }
    }
    return response
  },

  /*
   * updateGiverRecord
   * updates a giver record and all related data in a single API call.
   */

  async updateGiverRecord(payload) {
    const id = payload.giversettings.id
    const proxy = new UserProxy()
    try {
      const response = await proxy.updateGiverRecord(payload)
      if (response?.data) {
        const { giverId, coverFees } = response.data
        this.giverRecordData.giversettings = { coverFees, giverId, id }

        return { success: true, data: response.data }
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      return { success: false, error: error }
    }
  },

  /**
   * updateMemberPortalEmail
   * @param payload
   * @return {Promise<{success: boolean, error}|{data, success: boolean}>}
   */
  async updateMemberPortalEmail(payload) {
    const proxy = new UserProxy()
    try {
      const response = await proxy.updateMemberPortalEmail(payload)
      if (response?.data) {
        this.profileData.portalemail = response.data.portalemail
        return { success: true, data: response.data }
      }
      return { success: false }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      return { success: false, error: error }
    }
  },

  async updateStaffProfile(payload) {
    let response = {}
    response.success = false
    const proxy = new UserProxy()
    try {
      response = await proxy.patchStaffProfile(payload)
      if (response?.data) {
        this.profileData = response.data
        return { ...response.data, success: true }
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  },

  /**
   * fetchProfileGiverRecord
   * gets giver and payment info for the logged in member
   *
   *  @param payload
   *  const payload = {
   ind_id: this.profileData.ind_id,
   merchantId: this.organizationStore.getMerchantData.merchantId
   }
   * @param isMobileGiving
   * @returns {Promise<void>}
   */

  async fetchProfileGiverRecord(payload, isMobileGiving = false) {
    let response = {}
    const proxy = new GivingProxy()
    try {
      if (!isMobileGiving) {
        response = await proxy.getProfileGiverRecord(payload)
      } else {
        if (payload.ind_id) {
          response = await proxy.getMobileProfileGiverRecordSkIndId(payload)
        } else {
          response = await proxy.getMobileProfileGiverRecordGiverId(payload)
        }
      }

      if (
        isMobileGiving &&
        (response.data.error || response?.data === 'EXPIRED_TOKEN' || response?.data.message === 'TOKEN_INVALID')
      ) {
        return 'INVALID_SESSION'
      }

      if (response?.data) {
        if (response.data.individual) {
          this.profileData = response.data.individual
          // this.originalProfileData is use to compare to profileData for patching user data
          this.originalProfileData = Object.assign({}, response.data.individual)
        }

        this.giverRecordData = response.data.giverData
        this.paymentMethodData = response.data.paymentTypes

        if (isMobileGiving && response.data.giverData?.giversettings) {
          stores.useCheckOutStore().setUserPaysFeesMobileGiving(response.data.giverData.giversettings.coverFees)
        }
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  },

  // update a giver payment method
  async updatePaymentMethod(payload) {
    const response = {}
    const proxy = new GivingProxy()
    try {
      const response = await proxy.updatePaymentMethod(payload)
      if (response?.data) {
        return response.data
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  },

  // deletes a giver payment method
  async deletePaymentMethod(payload) {
    const response = {}
    const proxy = new GivingProxy()
    try {
      const response = await proxy.deletePaymentMethod(payload)
      if (response?.data) {
        return response.data
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  },

  // updates a giver payment method default
  async updatePaymentMethodAsDefault(payload) {
    const response = {}
    const proxy = new GivingProxy()
    try {
      const response = await proxy.updatePaymentMethodAsDefault(payload)
      if (response?.data) {
        // set the store data appropriately and unset all other status
        if (payload.default_token) {
          this.paymentMethodData.forEach((item) => {
            if (item.id !== payload.id) {
              item.default_token = false
            }
          })
        }
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return { ...response.data, success: true }
  },

  async checkExistingMemberPassword(payload) {
    const proxy = new SessionProxy()
    const { email, password, id } = payload
    return await proxy.checkMemberPassword({ id, email, password })
  },

  async checkExistingStaffPassword(payload) {
    const proxy = new SessionProxy()
    const { email, password, id } = payload
    return await proxy.checkStaffPassword({ id, email, password })
  },

  async hashNewPassword(password) {
    const proxy = new SessionProxy()
    return await proxy.hashPassword({ id: stores.useSessionStore().getMemberId, password })
  },

  async getProfileRefStates(webid) {
    const proxy = new GivingProxy()
    const response = await proxy.getProfileAttributeStates(webid)
    if (response?.status === 200) {
      this.userProfileStates = response.data
      return { success: true }
    }

    return { success: false }
  },

  async deactivateExistingGuestPaymentMethods(giverId) {
    const proxy = new GivingProxy()
    return await proxy.deactivateExistingGuestPaymentMethods(giverId)
  }
}
